import { useState } from "react";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { ArrowDownSvg } from "../icons";

const data = [
  { id: 1, name: "EN" },
  { id: 2, name: "GE" },
];

const LanguageSelect = ({ setExportLanguage }) => {
  const [language, setLanguage] = useState(data[0]);

  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage);
    setExportLanguage(selectedLanguage.name);
  };

  return (
    <div className="relative">
      <Listbox value={language} onChange={handleLanguageChange}>
        <ListboxButton className="w-full px-3 py-1.5 h-8 rounded border border-gray-200 bg-white flex justify-between items-center gap-2 cursor-pointer">
          <span className="text-gray-600 text-sm font-normal">
            {language.name}
          </span>
          <ArrowDownSvg className="w-3 h-3" />
        </ListboxButton>
        <ListboxOptions className="absolute w-full bg-white top-full mt-1 rounded border border-gray-200 py-1">
          {data.map((language) => (
            <ListboxOption
              key={language.id}
              value={language}
              className="transition-colors duration-300 px-2 py-1 text-xs font-normal text-gray-700 hover:bg-blue-100 cursor-pointer"
            >
              {language.name}
            </ListboxOption>
          ))}
        </ListboxOptions>
      </Listbox>
    </div>
  );
};

export default LanguageSelect;
