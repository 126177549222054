import { useState } from "react";
import ParcelsTable from "../table/groups/ParcelsTable";
import AddParcelForm from "../modals/groups/AddParcelForm";
import Modal from "../common/Modal";
import UploadParcelPictures from "../modals/groups/UploadParcelPictures";
import PicturesList from "../modals/groups/PicturesList";
import EditParcelForm from "../modals/groups/EditParcelForm";
import EditParcelPictures from "../modals/groups/EditParcelPictures";

const ParcelsLayout = ({
  currentPage,
  onNextPage,
  perPage,
  onPrevPage,
  handlePerPage,
  handlePageClick,
  showAddForm,
  showEditForm,
  addParcelFormRef,
  editParcelFormRef,
  formHeight,
  setShouldRefetch,
  handleShowEditForm,
  editParcelId,
  parcelsFormData,
  setParcelsFormData,
}) => {
  const [showUploadPicturesModal, setShowUploadPicturesModal] = useState(false);
  const [showEditPicturesModal, setShowEditPicturesModal] = useState(false);
  const [showPicturesListModal, setShowPicturesListModal] = useState(false);
  const [parcelId, setParcelId] = useState("");
  const [formData, setFormData] = useState({
    parcelGroupId: "",
    tds_code: "",
    roomNumber: "",
    parcelDetails: [
      {
        quantity: "",
        weight: "",
        length: "",
        width: "",
        height: "",
      },
    ],
    warehouseComment: "",
    warehouseCategoryId: "",
    "file[]": [],
  });
  const [editFormData, setEditFormData] = useState({
    parcelId: "",
    tds_code: "",
    roomNumber: "",
    parcelDetails: [
      {
        quantity: "",
        weight: "",
        length: "",
        width: "",
        height: "",
      },
    ],
    warehouseComment: "",
    warehouseCategoryId: "",
    "file[]": [],
  });

  const handleShowUploadPicturesModal = () => {
    setShowUploadPicturesModal(!showUploadPicturesModal);
  };

  const handleShowEditPicturesModal = () => {
    setShowEditPicturesModal(!showEditPicturesModal);
  };

  const handleShowPicturesList = (id) => {
    setShowPicturesListModal(true);
    setParcelId(id);
  };

  return (
    <>
      <AddParcelForm
        showAddForm={showAddForm}
        handleShowModal={handleShowUploadPicturesModal}
        addParcelFormRef={addParcelFormRef}
        formHeight={formHeight}
        formData={formData}
        setFormData={setFormData}
        setShouldRefetch={setShouldRefetch}
      />
      <EditParcelForm
        showEditForm={showEditForm}
        handleShowModal={handleShowEditPicturesModal}
        editParcelFormRef={editParcelFormRef}
        formHeight={formHeight}
        formData={editFormData}
        setFormData={setEditFormData}
        setShouldRefetch={setShouldRefetch}
        editParcelId={editParcelId}
      />
      <Modal
        showmodal={showEditPicturesModal}
        setShowModal={setShowEditPicturesModal}
      >
        <EditParcelPictures
          handleShowModal={handleShowEditPicturesModal}
          setFormData={setEditFormData}
          formData={editFormData}
        />
      </Modal>
      <Modal
        showmodal={showUploadPicturesModal}
        setShowModal={setShowUploadPicturesModal}
      >
        <UploadParcelPictures
          handleShowModal={handleShowUploadPicturesModal}
          setFormData={setFormData}
          formData={formData}
        />
      </Modal>

      <Modal
        showmodal={showPicturesListModal}
        setShowModal={setShowPicturesListModal}
      >
        <PicturesList
          parcelId={parcelId}
          handleShowModal={() => setShowPicturesListModal(false)}
        />
      </Modal>
      <ParcelsTable
        currentPage={currentPage}
        onNextPage={onNextPage}
        perPage={perPage}
        onPrevPage={onPrevPage}
        handlePerPage={handlePerPage}
        handlePageClick={handlePageClick}
        handleShowPicturesList={handleShowPicturesList}
        handleShowEditForm={handleShowEditForm}
        showEditForm={showEditForm}
        editParcelId={editParcelId}
        parcelsFormData={parcelsFormData}
        setParcelsFormData={setParcelsFormData}
      />
    </>
  );
};

export default ParcelsLayout;
